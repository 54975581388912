<template>
  <div class="Client">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0">
        <banner
          title="Wallet withdrawal"
          :breadcrumb="[{ label: 'Dashboard' }, { label: 'Wallet withdrawal' }]"
        >
        </banner>
        <div v-if="loading == true">
          <Spinner />
        </div>

        <div class="content-body" style="margin-top: -50px">
          <!-- users list start -->
          <section>
            <div class="row" style="margin-top: -20px">
              <div class="col-md-12 col-sm-12 col-xs-12">
                <div
                  class="card custom-card"
                
                >
                  <div class="row" style="padding: 10px 10px 10px 10px">

<div class="col-6"  >
  <h4 class="text"><strong>Wallet withdrawal</strong></h4>
</div>

<div class="col-6 text-end">
  <button
                    data-bs-toggle="modal"
                    data-bs-target="#new-task-modal"
                    title="Filter"
                    class="btn btn-sm text-white"
                    style="background-color: #00364f"
                  >
                    <strong> <font-awesome-icon icon="filter" /></strong>
                  </button>
</div>

                    <div
                      class="col-md-12 col-xl-12 col-sm-12 pt-1"
                      
                    >
                     
                      <div class="container-fluid table-scroll">
                        <table class="table table-hover table-sm">
                          <tr
                            class="text tr-head table-wrapper-scroll-y rounded-circle"
                            style="background-color: white"
                          >
                            <th
                              class="text-truncate"
                              style="
                                background-color: #cdcdcd;
                                border-radius: 8px 0px 0px 8px;
                              "
                            >
                              +
                            </th>
                             <!-- <th class="text-truncate" style="background-color: #cdcdcd">
                              User 
                            </th> -->
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Amount
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Ac No.
                            </th>

                            <th class="text-truncate" style="background-color: #cdcdcd">
                              UPI
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Date
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Payment Mode
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Status
                            </th>

                            <th
                              style="
                                background-color: #cdcdcd;
                                border-radius: 0px 8px 8px 0px;
                              "
                              class="text-truncate text-end"
                            >
                              Action
                            </th>
                          </tr>
                          <tbody class="text">
                            <tr
                              v-for="(walletWithdrawReq, index) in walletwithdrawalsReq"
                              :key="index"
                            >
                              <td>
                                <img
                                  style="width: 8px; margin-right: 5px"
                                  src="/assets/image/record.png"
                                  alt=""
                                />
                              </td>
                              <!-- <td class="text-truncate" v-if="walletWithdrawReq">
                             
                                <sapn v-if="walletWithdrawReq.user">   {{ walletWithdrawReq.user.name }} <small>( {{ walletWithdrawReq.user_type }} )      <div> {{ walletWithdrawReq.user.code }} </div></small></sapn>
                              
                              </td> -->
                              <td class="text-truncate" v-if="walletWithdrawReq">
                                {{ walletWithdrawReq.amount }}
                              </td>
                              <td class="text-truncate">

                              


                                <span v-if="walletWithdrawReq.beneficiaryaccount">
                                  {{
                                    walletWithdrawReq.beneficiaryaccount.account_number
                                  }}
                                </span>

                                <span v-else> NA </span>
                              </td>
                              <td class="text-truncate">
                                <span v-if="walletWithdrawReq.merchant_vpa">
                                  {{ walletWithdrawReq.merchant_vpa }}
                                </span>
                                <span v-else> NA </span>
                              </td>
                              <td class="text-truncate" v-if="walletWithdrawReq">
                                {{ dateTime(walletWithdrawReq.date) }}
                              </td>
                              <td class="text-truncate" v-if="walletWithdrawReq">
                                {{ walletWithdrawReq.paymentMode }}
                              </td>

                              <td class="text-truncate" v-if="walletWithdrawReq">
                                <p
                                  v-if="walletWithdrawReq.status == 'complete'"
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: green;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b> {{ walletWithdrawReq.status }}</b>
                                </p>
                                <p
                                  v-if="walletWithdrawReq.status == 'cancel'"
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: #f21000;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b> {{ walletWithdrawReq.status }}</b>
                                </p>

                                <p
                                  v-if="walletWithdrawReq.status == 'pending'"
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: #fc7f03;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b> {{ walletWithdrawReq.status }}</b>
                                </p>
                                <p
                                  v-if="walletWithdrawReq.status == 'reject'"
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: black;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b> {{ walletWithdrawReq.status }}</b>
                                </p>
                              </td>
                              <td class="text-truncate text-end">
                                <div class="btn-group btn-group-sm" role="group">
                                 <!-- 
                                   <button
                                    @click="walletWithdrawReqApproved(walletWithdrawReq)"
                                    class="btn btn-sm text-white"
                                    style="
                                      padding: 5px 5px 5px 5px;
                                      background-color: #00364f;
                                    "
                                  >
                                    <i
                                      class="fa fa-check-square-o"
                                      aria-hidden="true"
                                    ></i>
                                  </button> -->
                                  <button
                                    @click="walletWithdrawReqCancel(walletWithdrawReq)"
                                    class="btn btn-sm text-white"
                                    style="
                                      padding: 5px 5px 5px 5px;
                                      background-color: #f21000;
                                    "
                                  >
                                    <i class="fa fa-times-circle" aria-hidden="true"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <div class="row">
                      <div class="col-7">
                        <ul class="pagination">
                          <li
                            class="page-item"
                            @click="adminWalletWithdrawalGet(null, 'first')"
                          >
                            <a class="page-link" href="#" aria-label="Previous">
                              <span aria-hidden="true">&laquo;</span>
                            </a>
                          </li>
                          <li
                            class="page-item"
                            @click="adminWalletWithdrawalGet(null, 'prev')"
                          >
                            <a class="page-link" href="#">Previous</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination.current_page > 3"
                            @click="
                              adminWalletWithdrawalGet(pagination.current_page - 3)
                            "
                          >
                            <a class="page-link" href="#">{{
                              pagination.current_page - 3
                            }}</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination.current_page > 2"
                            @click="
                              adminWalletWithdrawalGet(pagination.current_page - 2)
                            "
                          >
                            <a class="page-link" href="#">{{
                              pagination.current_page - 2
                            }}</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination.current_page > 1"
                            @click="
                              adminWalletWithdrawalGet(pagination.current_page - 1)
                            "
                          >
                            <a class="page-link" href="#">{{
                              pagination.current_page - 1
                            }}</a>
                          </li>
                          <li
                            class="active page-item"
                            @click="
                              adminWalletWithdrawalGet(pagination.current_page)
                            "
                          >
                            <a class="page-link" href="#">{{
                              pagination.current_page
                            }}</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination.current_page < pagination.last_page - 4"
                            @click="
                              adminWalletWithdrawalGet(pagination.current_page + 1)
                            "
                          >
                            <a class="page-link" href="#">{{
                              pagination.current_page + 1
                            }}</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination.current_page < pagination.last_page - 5"
                            @click="
                              adminWalletWithdrawalGet(pagination.current_page + 2)
                            "
                          >
                            <a class="page-link" href="#">{{
                              pagination.current_page + 2
                            }}</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination.current_page < pagination.last_page - 6"
                            @click="
                              adminWalletWithdrawalGet(pagination.current_page + 3)
                            "
                          >
                            <a class="page-link" href="#">{{
                              pagination.current_page + 3
                            }}</a>
                          </li>
                          <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="adminWalletWithdrawalGet( pagination.current_page + 4 )"><a href="#">{{pagination.current_page + 4}}</a></li> -->
                          <li class="page-item">
                            <a class="page-link" href="#">...</a>
                          </li>

                          <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="adminWalletWithdrawalGet( pagination.last_page - 4 )"><a href="#">{{pagination.last_page - 4}}</a></li> -->
                          <li
                            class="page-item"
                            v-if="pagination.current_page < pagination.last_page - 3"
                            @click="
                              adminWalletWithdrawalGet(pagination.last_page - 3)
                            "
                          >
                            <a class="page-link" href="#">{{
                              pagination.last_page - 3
                            }}</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination.current_page < pagination.last_page - 2"
                            @click="
                              adminWalletWithdrawalGet(pagination.last_page - 2)
                            "
                          >
                            <a class="page-link" href="#">{{
                              pagination.last_page - 2
                            }}</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination.current_page < pagination.last_page - 1"
                            @click="
                              adminWalletWithdrawalGet(pagination.last_page - 1)
                            "
                          >
                            <a class="page-link" href="#">{{
                              pagination.last_page - 1
                            }}</a>
                          </li>

                          <li
                            class="page-item"
                            @click="adminWalletWithdrawalGet(null, 'next')"
                          >
                            <a class="page-link" href="#">Next</a>
                          </li>
                          <li
                            class="page-item"
                            @click="adminWalletWithdrawalGet(null, 'last')"
                          >
                            <a class="page-link" href="#">&raquo;</a>
                          </li>
                        </ul>
                      </div>
                      <div class="col-4 text-end">
                        Showing {{ pagination.from }} to {{ pagination.to }} of
                        {{ pagination.total }} entries
                      </div>
                      <div
                        class="col-1 text-end"
                        style="height: 30px; padding: 5px 5px 5px 5px"
                      >
                        <select
                          style="height: 30px; padding: 5px 5px 5px 5px"
                          class="form-select"
                          @change="changePageNo"
                          aria-label="Default select example"
                        >
                          <!-- <option value="5">5</option> -->
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <!-- users list ends -->
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>



  
  <!-- Right Sidebar starts -->
  <div class="modal modal-slide-in sidebar-todo-modal fade" id="new-task-modal">
    <div class="modal-dialog sidebar-sm">
      <div class="modal-content p-0">
        <form
          id="form-modal-todo"
          class="todo-modal needs-validation"
          novalidate
          onsubmit="return false"
        >
          <div
            class="modal-header align-items-center mb-1"
            style="background-color: #00364f"
          >
            <h5 class="modal-title text-white"><strong>Project Filter</strong></h5>
            <div
              class="todo-item-action d-flex align-items-center justify-content-between ms-auto text-white"
            >
              <span class="todo-item-favorite cursor-pointer me-75 text-white"
                ><i data-feather="star" class="font-medium-2"></i
              ></span>
              <i
                data-feather="x"
                class="cursor-pointer"
                data-bs-dismiss="modal"
                stroke-width="3"
                >X</i
              >
            </div>
          </div>
          <div class="modal-body flex-grow-1 pb-sm-0 pb-3">
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <div class="form-check">
                  <input
                  @click="valueNullFilter()"
                    v-model="filterType"
                    value="date"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="date"
                  />
                  <label class="form-check-label" for="date"> Date </label>
                </div>
              </li>

              <li class="list-group-item">
                <div class="form-check">
                  <input
                  @click="valueNullFilter()"
                    v-model="filterType"
                    value="merchant_vpa"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="upiId"
                  />
                  <label class="form-check-label" for="upiId"> UPI ID </label>
                </div>
              </li>
              <li class="list-group-item">
                <div class="form-check">
                  <input
                  @click="valueNullFilter()"
                    v-model="filterType"
                    value="paymentMode"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="paymentMode"
                  />
                  <label class="form-check-label" for="paymentMode">
                    Payment Mode
                  </label>
                </div>
              </li>
              <li class="list-group-item">
                <div class="form-check">
                  <input
                  @click="valueNullFilter()"
                    v-model="filterType"
                    value="user_type"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="user_type"
                  />
                  <label class="form-check-label" for="user_type">
                    User Type
                  </label>
                </div>
              </li>
              <!-- <li class="list-group-item">
                <div class="form-check">
                  <input
                    v-model="filterType"
                    value="date"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="date"  
                  />
                  <label class="form-check-label" for="date"> Date </label>
                </div>
              </li> -->
              <li class="list-group-item">
                <div class="form-check">
                  <input
                  @click="valueNullFilter()"
                    v-model="filterType"
                    value="txnDate"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="txnDate"
                  />
                  <label class="form-check-label" for="txnDate"> TXN Date </label>
                </div>
              </li>
              <!-- <li class="list-group-item">
                <div class="form-check">
                  <input
                    v-model="filterType"
                    value="txnRefNo"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                  />
                  <label class="form-check-label" for="flexRadioDefault1">
                    TXN Refrence No
                  </label>
                </div>
              </li> -->

              <!-- <li class="list-group-item">
                <div class="form-check">
                  <input
                    v-model="filterType"
                    value="status"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                  />
                  <label class="form-check-label" for="flexRadioDefault1"> Status </label>
                </div>
              </li> -->

              <!-- <li class="list-group-item">
                <div class="form-check">
                  <input
                    v-model="filterType"
                    value="service_id"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                  />
                  <label class="form-check-label" for="flexRadioDefault1">
                    Service 
                  </label>
                </div>
              </li> -->
              <li class="list-group-item">
                <div class="form-check">
                  <input
                  @click="valueNullFilter()"
                    v-model="filterType"
                    value="all"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                  />
                  <label class="form-check-label" for="flexRadioDefault1"> All </label>
                </div>
              </li>
            </ul>

            <div class="pt-1 pb-1">
              <!-- <label for="exampleFormControlInput1" class="form-label">Email address</label> -->
              <input
                type="date"
                v-if="
                  filterType == 'date' ||
                  filterType == 'txtDate' ||
                  filterType == 'completionDate'
                "
                class="form-control datepicker"
                v-model="filterValue"
                id="exampleFormControlInput1"
                placeholder="Enter Value"
              />
              <input
                type="text"
                v-if="
                  filterType == 'clientServiceNo' ||
                  filterType == 'merchant_vpa' ||
                  filterType == 'txn' ||
                  filterType == 'txnRefNo'
                "
                class="form-control"
                v-model="filterValue"
                id="exampleFormControlInput1"
                placeholder="Enter Value"
              />
              <input
                type="text"
                v-if="filterType == 'client_id'"
                @change="findRecordeByPanId"
                class="form-control"
                v-model="filterValue"
                id="exampleFormControlInput1"
                placeholder="Enter Value"
              />

              <select
                v-model="filterValue"
                v-if="filterType == 'status'"
                class="form-select"
                aria-label="Default select example"
              >
                <option selected disabled>Please Select...</option>
                <option value="pending">Pending</option>
              </select>


              <select

                v-model="filterValue"
                v-if="filterType == 'user_type'"
                class="form-select"
                aria-label="Default select example"
              >
                <option selected disabled>Please Select...</option>
                <option value="admin">Admin</option>
                <option value="professional">Professional</option>
                <option value="retailer">Retailer</option>
              </select>

<label class="mt-1"   v-if="filterType == 'user_type'">User Code</label>
              <input
                type="text"
                v-if="filterType == 'user_type'"
                @change="findRecordeByName"
                class="form-control "
      
                id="exampleFormControlInput1"
                placeholder="Enter Value"
              />



              <select
                v-model="filterValue"
                v-if="filterType == 'paymentMode'"
                class="form-select"
                aria-label="Default select example"
              >
                <option selected disabled>Please Select...</option>
                <option value="upi">UPI</option>
                <option value="neft">NEFT</option>
                <option value="imps">IMPS</option>
                <option value="rtgs">RTGS</option>
              </select>

              <select
                v-model="filterValue"
                v-if="filterType == 'service_id'"
                class="form-select"
                aria-label="Default select example"
              >
                <option selected disabled>Please Select...</option>
                <option
                  v-for="(service, index) in services"
                  :key="index"
                  :value="service.id"
                >
                  {{ service.name }}
                </option>
              </select>
            </div>

            <button
              v-if="filterType != 'user_type'"
              @click="adminWalletWithdrawalGet()"
              type="button"
              class="btn btn-success btn-sm"
            >
              Success
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Right Sidebar ends -->
</template>

<script>
import moment from "moment";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Spinner from "../../../components/admin/comman/Spinner.vue";
import Banner from "../../../components/admin/comman/Banner.vue";
// import Form from "vform";
// import jQuery from "jquery";
// let $ = jQuery;
export default {
  name: "AdminWalletWithdrawReq",
  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      services: [],
      filterValue: "",
      filterType: "",
      pagination: {
        current_page: 0,
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
        first_page_url: null,
        last_page_url: null,
        next_page_url: null,
        prev_page_url: null,
        path: null,
      },
      per_page: 10,
      loading: false,
      withdrawalEdit: "false",
      walletEdit: "false",
      walletwithdrawalsReq: [],
      wallet: {},
      walletwithdrawalForm: {
        wallet_id: null,
        amount: null,
        paymentMode: "",
        beneficiaryaccount_id: null,
        beneficiaryaccount_type: "",
        merchant_vpa: "",
        remark: "",
        errors: {},
      },
      user_id:''
    };
  },
  methods: {
    valueNullFilter(){
  this.filterValue = "";
},
    dateTime(value) {
      return moment(value).format("DD-MM-YYYY");
    },
    changePageNo(e) {
      this.per_page = e.target.value;
      this.adminWalletWithdrawalGet();
    },
    walletWithdrawReqApproved(value) {
      console.log(value);

      console.log(value.user_type);
      var form = {};

      if (value.paymentMode == "upi") {
        form = {
          paymentMode: value.paymentMode,
          amount: value.amount,
          merchant_vpa: value.merchant_vpa,
          beneAccNo: value.merchant_vpa,
          beneIFSC: value.merchant_vpa,
          beneName: value.merchant_vpa,
        };
      }
      if (value.paymentMode == "imps" || value.paymentMode == "neft") {
        form = {
          paymentMode: value.paymentMode,
          amount: value.amount,
          beneAccNo: value.beneficiaryaccount.account_number,
          beneIFSC: value.beneficiaryaccount.ifsc_code,
          beneName: value.beneficiaryaccount.account_holder_name,
        };
      }
      if (value.paymentMode == "rtgs") {
        form = {
          paymentMode: value.paymentMode,
          amount: value.amount,
          beneAccNo: value.beneficiaryaccount.account_number,
          beneIFSC: value.beneficiaryaccount.ifsc_code,
          beneName: value.beneficiaryaccount.account_holder_name,
        };
      }

      console.log(form);
      this.loading = true;
      this.$axios
        .post(`distributer/walletwithdrawal/approve/${value.id}`, form, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data);

          toast.success("Request Approved  successfully", {
            autoClose: 1000,
          });

          this.adminWalletWithdrawalGet();
        })

        .finally(() => (this.loading = false))
        .catch((error) => {
             
              //  var errors = error.response.data.error

             
                  // var msg = 'null';
                  
                  //     msg = error.response.data.error
                   
                  //   if(errors.beneIFSC[0]){
                  //     msg = errors.beneIFSC[0]
                  //   }
                  //   if(errors.merchant_vpa[0]){
                  //     msg = errors.merchant_vpa[0]
                  //   }

             var   dataMsg = ''
if(error.response.data.error.beneIFSC){
console.log(error.response.data.error.beneIFSC[0])
dataMsg = error.response.data.error.beneIFSC[0]
}

if(error.response.data.error.merchant_vpa){
console.log(error.response.data.error.merchant_vpa[0])
dataMsg = error.response.data.error.merchant_vpa[0]
}

if(dataMsg){
  this.$swal.fire({
                  position: "top-center",
                  icon: "error",
                  title: "<small>" +
                    
                    dataMsg
                    + "</small>",
                  showConfirmButton: true,
                  confirmButtonColor: "#00364f",
                });
}else{
  this.$swal.fire({
                  position: "top-center",
                  icon: "error",
                  title: "<small>" +
                    
                    error.response.data.error
                    + "</small>",
                  showConfirmButton: true,
                  confirmButtonColor: "#00364f",
                });
}
                  
             

              
              });
    },
    findRecordeByName(e){
   
        this.filterType
        console.log(e.target.value)

        console.log(this.filterValue)
var q = "";
          if(this.filterValue == 'admin'){
              q = 'admin?code='+e.target.value
          }

          if(this.filterValue == 'retailer'){
              q = 'retailer?code='+e.target.value
          }

          if(this.filterValue == 'professional'){
              q = 'professional?code='+e.target.value
          }

        this.$axios
        .get( `distributer/${q}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data[0].id);
        

      
          if(res.data.data.data[0].id){
            this.user_id = res.data.data.data[0].id
            this.adminWalletWithdrawalGet()
          }


        })
    },
    walletWithdrawReqCancel(value) {
      console.log(value);
      console.log(value.user_type);
      this.loading = true;
      this.$axios
        .post(
          `distributer/walletwithdrawal/cancel/${value.id}`,
          {},
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          console.log(res.data);

          toast.success("Request Canceled  successfully", {
            autoClose: 1000,
          });

          this.adminWalletWithdrawalGet();
        })

        .finally(() => (this.loading = false))
        .catch((error) => {
                this.$swal.fire({
                  position: "top-center",
                  icon: "error",
                  title: "<small>" + error.response.data.error + "</small>",
                  showConfirmButton: true,
                  confirmButtonColor: "#00364f",
                });
              });
    },
    adminWalletWithdrawalGet(page = null, pg = null) {
      console.log(page);
      console.log(pg);
      this.loading = true;
      var pageUrl = "";
      if (this.pagination.last_page_url && pg == "last") {
        pageUrl = pageUrl + this.pagination.last_page_url;
      } else if (this.pagination.first_page_url && pg == "first") {
        pageUrl = pageUrl + this.pagination.first_page_url;
      } else if (this.pagination.prev_page_url && pg == "prev") {
        pageUrl = pageUrl + this.pagination.prev_page_url;
      } else if (this.pagination.next_page_url && pg == "next") {
        pageUrl = pageUrl + this.pagination.next_page_url;
      }

      if (pg == null) {
        


        if( this.filterType == 'all'){
            this.filterValue = ''
            this.filterType = ''
            this.user_id = ''
          }

        


          if(this.filterValue != null && this.filterType != 'all'){
                      if(this.filterType == 'name'){
                        pageUrl += `distributer/walletwithdrawal?per_page=${this.per_page}&searchTerm=${this.filterValue}`;
          
                      }else{
                                if(this.user_id != null){
                                  pageUrl += `distributer/walletwithdrawal?per_page=${this.per_page}&${this.filterType}=${this.filterValue}&user_id=${this.user_id}`;
          
                                }else{
                                  pageUrl += `distributer/walletwithdrawal?per_page=${this.per_page}&${this.filterType}=${this.filterValue}`;
          
                                }
                      
                      }
           }else{
            pageUrl += `distributer/walletwithdrawal?per_page=${this.per_page}`;
           }



      } else {
        pageUrl += `&per_page=${this.pagination.per_page}`;
      }
      if (this.table_search) {
        pageUrl += `&searchTerm=${this.table_search}`;
      }
      if (page != null) {
        pageUrl += `&page=${page}`;
      }

      this.$axios
        .get(pageUrl, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.walletwithdrawalsReq = res.data.data.data;
          this.pagination = res.data.data;

          this.filterValue = ''
            this.filterType = ''
            this.user_id = ''
        })
        .finally(() => (this.loading = false));
    },  
    servicesLoad() {
      this.$axios
        .post(
          `allservice`,
          {},
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          this.services = res.data.data;
          console.log(res.data.data);
        });
    }, 
    deletePackage(id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be Delete it",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00364f",
          cancelButtonColor: "#f21000",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .delete(`admin/clientserviceticket/${id}`, {
                headers: { Authorization: "Bearer " + localStorage.accessToken },
              })
              .then((res) => {
                res;
                toast.success("Ticket Deleted  Succesfully", {
                  autoClose: 1000,
                });
                this.loadTicket();
              })
              .catch((error) => {
                error;
              });
          }
        });
    },
    loadPageTitle(){
      console.log('--------------------------');
        
        document.title =this.$store.state.distributerPageTitles.withdrawles
    console.log(this.$store.state.distributerPageTitles.withdrawles)
    console.log('--------------------------');
    }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.adminWalletWithdrawalGet();
      this.servicesLoad();
      this.loadPageTitle()
    }
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}
@media screen and (max-width: 767px) {
  .mt-top-numbers {
    margin-top: -26px;
  }
}

.h1-heading {
  color: red;
  font-size: 3vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.btns {
  display: inline-flex;
}
.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}
.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}
h4[data-v-69e5a8da] {
  color: #fff;
  font-family: poppins, Sans-serif;
  font-weight: 600;
  font-size: 25;
}


input:checked {
  /* background-color: #7367f0; */
  border-color: #ff000c;
  border-width: 5px;
}
.form-check-input:checked {
  background-color: #fdfdfd;
  /* border-color: #7367f0; */
}
</style>
